import React, { FunctionComponent, useState, useLayoutEffect } from 'react';
import { NavItemProps } from '../../resources/NavBar.interface';

const styles = require('./NavItem.module.scss');
const NavItem: FunctionComponent<NavItemProps> = ({
  rel = 'noopener noreferrer',
  target,
  children,
  className,
  active = false,
  alignSelf = null,
  title,
  onClick,
  mobileVisible = false
}) => {
  const [classNames, setClassNames] = useState('');
  const [align, setAlign] = useState(alignSelf);
  const [activeState, setActiveState] = useState(active);
  const [visibleState, setVisibleState] = useState(mobileVisible);

  useLayoutEffect(() => {
    setActiveState(activeState ? styles[`active`] : false);
  }, [activeState]);

  useLayoutEffect(() => {
    setVisibleState(visibleState ? styles[`visible`] : false);
  }, [visibleState]);

  useLayoutEffect(() => {
    setAlign(styles[`${alignSelf}`]);
  }, [alignSelf]);

  useLayoutEffect(() => {
    setClassNames([styles.item, activeState, className, align, visibleState].filter((item) => item).join(' '));
  }, [className, activeState, align, visibleState]);
  return (
    <li role="menuitem" className={classNames}>
      {onClick ? (
        <a className={styles.link} onClick={onClick} rel={rel}>
          {children}
          {title}
        </a>
      ) : (
        <span className={styles.link}>
          {children} {title}
        </span>
      )}
    </li>
  );
};

export default NavItem;
