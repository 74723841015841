import React, { FunctionComponent, MouseEvent, KeyboardEvent, SyntheticEvent } from 'react';
const Styles = require('./UserBoxItem.module.scss');

interface IUserBoxItem {
  title: string;
  href?: string;
  rel?: string;
  action?: (event?: SyntheticEvent) => void;
}
const UserBoxItem: FunctionComponent<IUserBoxItem> = ({
  href,
  title,
  rel = 'noopener noreferrer',
  action = () => {}
}) => {
  const redirect = () => {
    if (href) {
      window.location.href = href;
    }
  };
  const handleClick = (event: MouseEvent) => {
    event.preventDefault();
    action(event);
    redirect();
  };
  const handleKeyDown = (event: KeyboardEvent) => {
    event.preventDefault();
    if (event.keyCode === 13) {
      action(event);
      redirect();
    }
  };
  return (
    <li className={Styles.userBoxItem} key={title} role="menuitem">
      {href ? (
        <a className={Styles.userBoxLink} href={href} rel={rel} onClick={handleClick} onKeyDown={handleKeyDown}>
          {title}
        </a>
      ) : (
        <span role="button" tabIndex={0} className={Styles.userBoxLink} onClick={handleClick} onKeyDown={handleKeyDown}>
          {title}
        </span>
      )}
    </li>
  );
};
export default UserBoxItem;
