import React, { useState, useEffect } from 'react';
import { activateUser, deactivateUser } from './services/users.service';
import CreateUserContainer from '../create-user-container/CreateUserContainer';
import { getProducers, getUsers } from '../../services/getUsers.service';
import { IUserInfo } from '../../models/user';
import { TableSearch } from '../../components';
import styles from './UsersContainer.module.scss';
import { Button, message } from 'antd';
import { AiOutlineUserAdd } from 'react-icons/ai';
import AdministrateUserModal from './components/administrate-user-modal/AdministrateUserModal';
import EditUserModal from './components/edit-user-modal/EditUserModal';
import ChangePasswordModal from '../change-password-modal/ChangePasswordModal';
import { isSuperAdmin } from '../../helpers/user.helper';

const UsersContainer: React.FC = () => {
  const [showAdministrateModal, setShowAdministrateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  const [users, setUsers] = useState<IUserInfo[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<IUserInfo[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedUserInfo, setSelectedUserInfo] = useState({} as IUserInfo);

  useEffect(() => {
    {
      getUsers()
        .then((res) => {
          if (res.users) {
            setUsers(res.users);
            setFilteredUsers(res.users);
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response) {
            message.error(err.response.data.description);
            return;
          }
          message.error('Error al Comunicarse con el Servidor!');
        });
    }
  }, [changesMade]);

  const columns = [
    {
      title: 'Usuario',
      dataIndex: 'username',
      key: 'username',
      width: 'auto'
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      width: 'auto'
    },
    {
      title: 'Rut',
      dataIndex: 'rut',
      key: 'rut',
      width: 'auto'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 'auto'
    },
    {
      title: 'Activo',
      dataIndex: 'active',
      key: 'active',
      width: 'auto',
      render: (active: boolean) => {
        if (active === true) {
          return 'Activo';
        } else {
          return 'Desactivado';
        }
      }
    },
    {
      title: 'Rol',
      dataIndex: 'role',
      key: 'role',
      width: 'auto',
      render: (role: number) => {
        if (role === 1) {
          return 'Admin';
        }
        if (role === 0) {
          return 'Usuario';
        }
        if (role === 2) {
          return 'Superadmin';
        }
        return role;
      }
    },
    {
      title: 'Acciones',
      dataIndex: 'id',
      key: 'actions',
      width: 'auto',
      render: (id: number) => (
        <Button key={id} onClick={() => handleAdministrate(id)} type="primary">
          Administrar
        </Button>
      )
    }
  ];
  const filterByNameInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currValue = e.target.value;
    const filteredData = users.filter((entry) => {
      if (entry.username.toLowerCase().includes(currValue.toLowerCase())) {
        return true;
      }
      if (entry.name.toLowerCase().includes(currValue.toLowerCase())) {
        return true;
      }
      if (entry.email.toLowerCase().includes(currValue.toLowerCase())) {
        return true;
      }
    });
    setFilteredUsers(filteredData);
  };

  const handleAdministrate = (id: number) => {
    users.map((value, index) => {
      if (value.id === id) {
        setSelectedUserInfo(value);
      }
      return value;
    });

    setShowAdministrateModal(true);
  };

  const showCreateUserModal = () => {
    setShowModal(true);
  };

  const handleCancel = () => {
    setShowModal(false);
    setShowEditModal(false);
  };

  const handleActivate = () => {
    activateUser(selectedUserInfo.id)
      .then((res) => {})
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.description);
          return;
        }
        message.error('Error al Comunicarse con el Servidor!');
      });
    setChangesMade(!changesMade);
    setShowAdministrateModal(false);
  };

  const handleDeactivate = () => {
    deactivateUser(selectedUserInfo.id)
      .then((res) => {})
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.description);
          return;
        }
        message.error('Error al Comunicarse con el Servidor!');
      });
    setChangesMade(!changesMade);
    setShowAdministrateModal(false);
  };
  const handleChangePassword = () => {
    setShowAdministrateModal(false);
    setShowPasswordModal(true);
  };
  const handleEdit = () => {
    setShowAdministrateModal(false);
    setShowEditModal(true);
  };
  return (
    <div className={styles.container}>
      <TableSearch
        onRow={(record: IUserInfo, _: number) => {
          return {
            onClick: (e: MouseEvent) => {
              handleAdministrate(record.id);
            }
          };
        }}
        className={styles.table}
        columns={columns}
        data={filteredUsers}
        title="Usuarios"
        filter={filterByNameInput}
      >
        <Button onClick={showCreateUserModal} className={styles.delete} type="primary">
          <AiOutlineUserAdd className={styles.icon} />
        </Button>
      </TableSearch>
      <CreateUserContainer
        visible={showModal}
        setVisible={setShowModal}
        changes={changesMade}
        setChanges={setChangesMade}
      />
      <AdministrateUserModal
        visible={showAdministrateModal}
        setShowModal={setShowAdministrateModal}
        onEdit={handleEdit}
        onActivate={handleActivate}
        onDeactivate={handleDeactivate}
        onChangePassword={handleChangePassword}
        userInfo={selectedUserInfo}
      />
      <EditUserModal
        visible={showEditModal}
        setVisible={setShowEditModal}
        user={selectedUserInfo}
        setChanges={setChangesMade}
        changes={changesMade}
      />
      <ChangePasswordModal visible={showPasswordModal} setVisible={setShowPasswordModal} userId={selectedUserInfo.id} />
    </div>
  );
};

export default UsersContainer;
