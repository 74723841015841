import { FaFolder, FaTruckMoving } from 'react-icons/fa';
import { getCurrentUser } from '../../../../helpers/user.helper';
import { NavbarProps } from './resources/NavBar.interface';
import { NavList, Align, NavItem, UserBox, UserBoxItem } from './components/index';
import { clearSessionStorage } from '../../../../helpers/cookies.helper';
import NavBrand from './components/nav-brand/NavBrand';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ChangePasswordModal from '../../../change-password-modal/ChangePasswordModal';

const styles = require('./Navbar.module.scss');

const Navbar: React.FC<NavbarProps> = ({ backgroundColor = '#001529' }) => {
  const Styles = {
    backgroundColor: backgroundColor
  };

  const [showChangePassword, setShowChangePassword] = useState(false);
  const currentUser = getCurrentUser();
  const navigate = useNavigate();

  const handleChangePassword = () => {
    setShowChangePassword(true);
  };

  const handlerCloseSession = () => {
    clearSessionStorage();
    return;
  };

  return (
    <nav className={`${styles.nav} ${styles.navbar}`} style={Styles}>
      <NavBrand className={styles.logo} />
      <NavList alignment={Align.Start}>
        <NavItem
          onClick={() => {
            window.location.href = 'files/shared';
          }}
          active={true}
        >
          <FaFolder />
          Archivos
        </NavItem>
      </NavList>

      <ChangePasswordModal
        visible={showChangePassword}
        setVisible={setShowChangePassword}
        userId={currentUser?.id ?? -1}
      />

      <UserBox name={`${currentUser?.name.split(' ')[0]}`}>
        <UserBoxItem title={'Cambiar Contraseña'} action={handleChangePassword} />
        <UserBoxItem title={'Cerrar Sesión'} href={`/login`} action={handlerCloseSession} />
      </UserBox>
    </nav>
  );
};

export default Navbar;
