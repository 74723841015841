import { Button, Descriptions, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { IUserInfo } from '../../../../models/user';
import styles from './AdministrateUserModal.module.scss';

interface IAdministrateUserModalProps {
  visible: boolean;
  setShowModal: any;
  userInfo: IUserInfo;
  onEdit: any;
  onActivate: any;
  onDeactivate: any;
  onChangePassword: any;
}

const AdministrateUserModal: React.FC<IAdministrateUserModalProps> = (props: IAdministrateUserModalProps) => {
  const navigate = useNavigate();
  const handleClose = () => {
    props.setShowModal(false);
  };

  const user = props.userInfo;

  const handleViewLots = () => {
    navigate(`/lots/${props.userInfo.username}/`);
  };

  const handleViewFiles = () => {
    navigate(`/files/${props.userInfo.username}/`);
  };
  const renderActive = (): string => {
    return user.active ? 'Activo' : 'Desactivado';
  };

  const renderRole = (): string => {
    switch (user.role) {
      case 0:
        return 'Usuario';
      case 2:
        return 'Superadmin';
      case 1:
        return 'Admin';

      default:
        return 'Usuario';
    }
  };

  return (
    <Modal closable visible={props.visible} onOk={handleClose} onCancel={handleClose}>
      <Descriptions
        className={styles.description}
        layout={'horizontal'}
        title={`Información del Usuario`}
        column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
      >
        <Descriptions.Item label="Usuario">{user.username}</Descriptions.Item>
        <Descriptions.Item label="Nombre">{user.name}</Descriptions.Item>
        <Descriptions.Item label="Rut">{user.rut}</Descriptions.Item>
        <Descriptions.Item label="Email">{user.email}</Descriptions.Item>
        <Descriptions.Item label="Dirección">{user.address}</Descriptions.Item>
        <Descriptions.Item label="Teléfono">{user.phone}</Descriptions.Item>
        <Descriptions.Item label="Rol">{renderRole()}</Descriptions.Item>
        <Descriptions.Item label="Intentos Fallidos de Login">{user.attempts}</Descriptions.Item>
        <Descriptions.Item label="Activo">{renderActive()}</Descriptions.Item>
        <Descriptions.Item label="Logins">{user.logins}</Descriptions.Item>
      </Descriptions>
      <div className={styles.buttonContainer}>
        <Button className={styles.button} type="primary" onClick={props.onEdit}>
          Editar
        </Button>
        <Button className={styles.button} type="primary" onClick={user.active ? props.onDeactivate : props.onActivate}>
          {user.active ? 'Desactivar' : 'Activar'}
        </Button>
        <Button className={styles.button} type="primary" onClick={props.onChangePassword}>
          Cambiar Contraseña
        </Button>
      </div>
    </Modal>
  );
};

export default AdministrateUserModal;
