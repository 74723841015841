import React, { FunctionComponent } from 'react';
const styles = require('./AvatarIcon.module.scss');

interface IconsProps {
  icon: React.ReactChild;
}

const AvatarIcon: FunctionComponent<IconsProps> = ({ icon }) => <span className={`${styles.icon}`}>{icon}</span>;

export default AvatarIcon;
