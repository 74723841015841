import { Button, Col, Divider, Form, InputNumber, message, Result, Row, Upload } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import { RcFile } from 'antd/lib/upload';
import { AiOutlineUpload } from 'react-icons/ai';
import { getRLPCosts, uploadReceptionFile } from './services/rlp.service';
import styles from './RLPContainer.module.scss';
import { Children, useEffect, useState } from 'react';
import { IRLPCosts } from './models/response';
import { globalService } from '../../services/global.service';

const costIndexes = [
  'costo_proceso',
  'costo_proceso_sin_calibrar',
  'costo_proceso_mecanico',
  'costo_proceso_manual',
  'costo_exportacion_calibradas',
  'costo_exportacion_sin_calibrar',
  'costo_exportacion_mecanico',
  'costo_exportacion_manual',
  'comision_exportacion',
  'p_36',
  'p_34',
  'p_32',
  'p_30',
  'precalibre',
  'descarte_cal',
  'p_30_nsc',
  'p_30_nsc_serr',
  'p_30_nsc_serr_precalibre',
  'p_30_nsc_serr_descarte',
  'lhp_80',
  'cl',
  'lahp_80',
  'c_la',
  'yhp_80',
  'c_y',
  'cuartillo',
  'industrial',
  'mitades_el',
  'mitades_l',
  'mitades_la',
  'cuartos_el',
  'cuartos_l',
  'mitades_y',
  'cuartos_y',
  'cuartos_la',
  'cuartillos_l',
  'cuartillos_la'
];

const costLabels = [
  'Costo Proceso NCC',
  'Costo Proceso NCC Sin Calibrar',
  'Costo Proceso Mecánico',
  'Costo Proceso Manual',
  'Costo Exportación Calibradas',
  'Costo Exportación Sin Calibrar',
  'Costo Exportación Mecánico',
  'Costo Exportación Manual',
  'Comision Exportación',
  'Precio 36+',
  'Precio 34-36',
  'Precio 32-34',
  'Precio 30-32',
  'Precio Precalibre',
  'Precio Descarte Calibrado',
  'Precio 30+ - Sin Calibrar',
  'Precio 30+ Serr',
  'Precio Precalibre Serr',
  'Precio Descarte Serr',
  'Precio LHP-80',
  'Precio C-L',
  'Precio LAHP-80',
  'Precio C-LA',
  'Precio YHP-80',
  'Precio C-Y',
  'Precio Cuartillo',
  'Precio Industrial',
  'Precio Mitades EL',
  'Precio Mitades L',
  'Precio Mitades LA',
  'Precio Cuartos EL',
  'Precio Cuartos L',
  'Precio Mitades Y',
  'Precio Cuartos Y',
  'Precio Cuartos LA',
  'Precio Cuartillos L',
  'Precio Cuartillos LA'
];

const RLPContainer: React.FC = () => {
  const [form] = Form.useForm();
  const formData = new FormData();
  const [costs, setCosts] = useState<IRLPCosts>({} as IRLPCosts);
  const [loading, setLoading] = useState(true);
  const [showResult, setShowResult] = useState(false);

  const initialValues = [
    costs.costo_proceso,
    costs.costo_proceso_sin_calibrar,
    costs.costo_proceso_mecanico,
    costs.costo_proceso_manual,
    costs.costo_exportacion_calibradas,
    costs.costo_exportacion_sin_calibrar,
    costs.costo_exportacion_mecanico,
    costs.costo_exportacion_manual,
    costs.comision_exportacion,
    costs.p_36,
    costs.p_34,
    costs.p_32,
    costs.p_30,
    costs.precalibre,
    costs.descarte_cal,
    costs.p_30_nsc,
    costs.p_30_nsc_serr,
    costs.p_30_nsc_serr_precalibre,
    costs.p_30_nsc_serr_descarte,
    costs.lhp_80,
    costs.cl,
    costs.lahp_80,
    costs.c_la,
    costs.yhp_80,
    costs.c_y,
    costs.cuartillo,
    costs.industrial,
    costs.mitades_el,
    costs.mitades_l,
    costs.mitades_la,
    costs.cuartos_el,
    costs.cuartos_l,
    costs.mitades_y,
    costs.cuartos_y,
    costs.cuartos_la,
    costs.cuartillos_l,
    costs.cuartillos_la
  ];

  useEffect(() => {
    getRLPCosts()
      .then((res) => {
        if (res.costs) {
          setCosts(res.costs);
          setLoading(false);
          return;
        }
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data);
          return;
        }
        message.error('Error Comunicandose con el Servidor!');
      });
  }, []);

  const handleUpload = (values: any) => {
    for (var value in values) {
      formData.append(value, values[value]);
    }
    uploadReceptionFile(formData)
      .then((res) => {
        if (res.success) {
          setShowResult(true);
          return;
        }
        message.error('Error al Generar Calculo RLP!');
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.description);
          return;
        }
        message.error('Error al Comunicarse con el Servidor!');
      });
  };

  const beforeUploadCosts = (file: RcFile, fileList: RcFile[]) => {
    formData.append('costs-file', file);
    return false;
  };

  const beforeUploadReception = (file: RcFile, fileList: RcFile[]) => {
    formData.append('reception-file', file);
    return false;
  };

  const getInputs = () => {
    var inputs: any[] = [];
    for (var i = 0; i < costIndexes.length; i++) {
      if (i == 9 || i == 15 || i == 19 || i == 27) {
        inputs.push(<Divider />);
      }
      inputs.push(
        <Col span={8} key={costIndexes[i]}>
          <Form.Item
            className={styles.formItem}
            name={`${costIndexes[i]}`}
            label={`${costLabels[i]}`}
            initialValue={initialValues[i]}
            rules={[
              {
                required: true,
                message: 'Input something!'
              }
            ]}
          >
            <InputNumber decimalSeparator="," />
          </Form.Item>
        </Col>
      );
    }
    inputs.push(<Divider />);
    return inputs;
  };

  return (
    <div className={styles.container}>
      <Header className={styles.header}>
        <h3 style={{ fontWeight: 600 }}>Generar Calculo RLP</h3>
      </Header>
      <Divider className={styles.divider} />
      {loading ? (
        <></>
      ) : (
        <Form name="file_upload" onFinish={handleUpload} layout={'inline'}>
          <Row gutter={24}>{getInputs()}</Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                name="files"
                rules={[
                  {
                    required: true,
                    message: 'Ingresa un archivo!'
                  }
                ]}
              >
                <Upload multiple={false} beforeUpload={beforeUploadReception}>
                  <Button icon={<AiOutlineUpload />}>Seleccionar Archivo</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col
              span={24}
              style={{
                textAlign: 'right'
              }}
            >
              <Form.Item className={styles.formButtons}>
                <Button type="primary" htmlType="submit">
                  Cargar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
      {showResult ? (
        <Result
          className={styles.successMessage}
          status="success"
          title="Se Ha Calculado El RLP Exitosamente!"
          subTitle="Con el boton puedes descargar el archivo que contiene los resultados!"
          extra={[
            <Button type="primary" key="ceased" href={`${globalService.apiUrl}/api/v1/rlp/get-result`}>
              Planilla Resultado
            </Button>
          ]}
        />
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default RLPContainer;
