import { Form, Input, Button } from 'antd';
import styles from './LoginCard.module.scss';
import LoginImage from './assets/logo.png';
import backgroundImage from './assets/background.jpg';

interface LoginCardProps {
  onFinish: (values: Object) => void;
}

const LoginCard: React.FC<LoginCardProps> = ({ onFinish }) => {
  return (
    <div className={styles.container} style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className={styles.cardContainer}>
        <div className={styles.card}>
          <div className={styles.cardImage}>
            <img alt="" src={LoginImage}></img>
          </div>
          <div className={styles.cardBody}>
            <Form
              className={styles.loginForm}
              name="basic"
              labelCol={{
                span: 8
              }}
              wrapperCol={{
                span: 16
              }}
              initialValues={{
                remember: true
              }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                label="Usuario"
                name="username"
                rules={[
                  {
                    required: true,
                    message: 'Please input your username!'
                  }
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Contraseña"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!'
                  }
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16
                }}
              >
                <Button type="primary" htmlType="submit">
                  Ingresar
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoginCard;
