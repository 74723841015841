import { http } from "../../../api/http.provider";
import { IRLPCosts, IRLPCostsResponse, IRLPResponse } from "../models/response";


export const uploadReceptionFile = (file: any): Promise<IRLPResponse> =>{
    let url = "api/v1/rlp/generate";
    return http.post(url, file, {
        headers: {
            'Content-Type': 'multipart/form-data'
          },
        withCredentials: true
    }).then(({data})=> data);
}

export const getRLPCosts = (): Promise<IRLPCostsResponse> =>{
    let url = "api/v1/rlp/costs";
    return http.get(url, {withCredentials:true}).then(({data}) => data);
}

