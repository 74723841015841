import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginCard } from '../../components';
import { setCookie } from '../../helpers/cookies.helper';
import { decodedJwt, setJwtToken } from '../../helpers/jwt.helper';
import { getCurrentUser, isAdmin } from '../../helpers/user.helper';
import { getIPAddress, postCredentials } from './services/login.service';

const LoginContainer: React.FC = () => {
  const navigate = useNavigate();
  const [ip, setIp] = useState('');
  useEffect(() => {
    getIPAddress().then((res) =>{
      if(res.IPv4){
        setIp(res.IPv4);

      }
    }).catch((err) =>{
      console.log('error getting ip');
    })
  }, []);

  const onFinish = (values: any) => {
    const username = values.username;
    const password = values.password;

    postCredentials(username, password, ip)
      .then((res) => {
        if (res.token) {
          setJwtToken(res.token);
          try {
            decodedJwt();
          } catch (err) {
            message.error('Token Malformado o no Encontrado!');
            return;
          }

          const user = getCurrentUser();

          if (isAdmin()) {
            return navigate('/admin/users');
          }
          return navigate(`/lots/${user?.username}`);
        }
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.description);
          return;
        }
        message.error('Error al comunicarse con el servidor');
      });
  };
  return <LoginCard onFinish={onFinish} />;
};

export default LoginContainer;
