import React from 'react';
import { ConfigProvider, Layout, message } from 'antd';
import esES from 'antd/lib/locale/es_ES';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Navbar, PrivateRoute, Sidebar } from '../../components';
import { Fixed } from './components/navbar/components';
import { getCurrentUser, isAdmin } from '../../helpers/user.helper';
import IdleTimerContainer from '../idle-timer-container/IdleTimerContainer';
import FilesContainer from '../files-container/FilesContainer';
import UsersContainer from '../users-container/UsersContainer';
import RLPContainer from '../rlp-container/RLPContainer';

const styles = require('./MainContainer.module.scss');

const { Header, Content } = Layout;

const MainContainer: React.FC = () => {
  const user = getCurrentUser();
  message.config({ duration: 5 });

  return (
    <>
      {user && user.id ? (
        <>
          {' '}
          <ConfigProvider locale={esES}>
            <IdleTimerContainer>
              <Layout className={styles.layout}>
                <Header className={styles.navbarContainer}>
                  <Navbar fixed={Fixed.Top}></Navbar>
                </Header>
                {isAdmin() ? (
                  <Layout>
                    <Sidebar />
                    <Layout>
                      <Content className={styles.container}>
                        <Routes>
                          <Route
                            path="files/shared"
                            element={
                              <PrivateRoute requireUser={false}>
                                <FilesContainer singleClick={false} />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path="files/shared/:path"
                            element={
                              <PrivateRoute requireUser={false}>
                                <FilesContainer singleClick={false} />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path="admin/rlp"
                            element={
                              <RLPContainer/>
                            }
                          />

                          <Route path="admin/users" element={<UsersContainer />} />
                          <Route path="*" element={<Navigate to={`files/shared`} replace />} />
                        </Routes>
                      </Content>
                    </Layout>
                  </Layout>
                ) : (
                  <Layout>
                    <Content className={styles.container}>
                      <Routes>
                        <Route
                          path="files/shared"
                          element={
                            <PrivateRoute requireUser={false}>
                              <FilesContainer singleClick={true} />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="files/shared/:path"
                          element={
                            <PrivateRoute requireUser={false}>
                              <FilesContainer singleClick={true} />
                            </PrivateRoute>
                          }
                        />
                        <Route path="*" element={<Navigate to={'files/shared'} replace />} />
                      </Routes>
                    </Content>
                  </Layout>
                )}
              </Layout>
            </IdleTimerContainer>
          </ConfigProvider>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default MainContainer;
