import React, { FunctionComponent, useState, useRef } from 'react';
import Avatar from 'antd/lib/avatar/avatar';
import Icon from './icon.svg';
const styles = require('./UserBox.module.scss');

interface IUserBox {
  name: string;
  isVisible?: boolean;
  srcAvatar?: string;
  id?: string;
}
const UserBox: FunctionComponent<IUserBox> = ({ name, isVisible = false, children, id = 'userBox' }) => {
  const node: any = useRef();
  const [isVisibleState, isVisibleSetstate] = useState(isVisible);
  const handlerClick = (event: any) => {
    if (node.current.contains(event.target)) {
      isVisibleSetstate(!isVisibleState);
      return;
    }
    isVisibleSetstate(false);
  };

  window.onclick = (event: any) => {
    if (node && node.current && !node.current.contains(event.target)) {
      handlerClick(event);
    }
  };
  return (
    <div id={id} ref={node} className={styles.userBox} role="menubar">
      <button
        className={styles.userBoxButton}
        type="button"
        onClick={(event) => handlerClick(event)}
        aria-haspopup="true"
        role="menuitem"
      >
        <span className={styles.userName}>{name}</span>

        <Avatar
          size={36}
          style={{
            color: '#fff',
            backgroundColor: 'rgba(158, 207, 250, 0.25)'
          }}
        >
          {name.split('')[0].toUpperCase()}
        </Avatar>
        <img src={Icon} alt="Profile Icon"></img>
      </button>
      {isVisibleState && (
        <ul className={styles.userBoxList} aria-hidden="true" role="menu">
          {children}
        </ul>
      )}
    </div>
  );
};
export default UserBox;
