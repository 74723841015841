import { Table, Input, Layout, Divider } from 'antd';
import { ChangeEventHandler, ReactElement } from 'react';
import styles from './TableSearch.module.scss';

interface TableSearchProps {
  className?: string;
  onRow?: any;
  title?: string;
  data: Array<any>;
  columns: Array<any>;
  filter: ChangeEventHandler<HTMLInputElement>;
  children?: ReactElement<any, any>;
}

const Header = Layout.Header;

const TableSearch: React.FC<TableSearchProps> = (props: TableSearchProps) => {
  return (
    <div className={styles.container}>
      <Header className={styles.header}>
        <h3 className={styles.title}>{props.title}</h3>
        <div className={styles.inputContainer}>
          <Input.Search placeholder="Buscar" onChange={props.filter} className={styles.input} />
          {props.children ?? <></>}
        </div>
      </Header>
      <Divider className={styles.divider} />
      <div className={styles.tableContainer}>
        <Table
          onRow={props.onRow}
          className={props.className}
          dataSource={props.data}
          columns={props.columns}
          pagination={{ defaultPageSize: 5, showSizeChanger: true, pageSizeOptions: ['5', '10', '20'] }}
        ></Table>
      </div>
    </div>
  );
};

export default TableSearch;
