import { FileCard } from '../../components';
import React, { useState, useEffect } from 'react';
import { Row, Col, Layout, Menu, Breadcrumb, Divider, Input, Button, message } from 'antd';
import { AiOutlineUpload, AiOutlineDelete, AiOutlineFolderAdd } from 'react-icons/ai';
import styles from './FilesContainer.module.scss';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { Link } from 'react-router-dom';
import ActionModals from './components/action-modals/ActionModals';
import { isAdmin } from '../../helpers/user.helper';
import { IFile } from '../../models/file';
import { getFolderContent } from './services/files.service';
import { getFile } from '../../services/files.service';
import { IoChevronBackCircleOutline, IoSearchOutline } from 'react-icons/io5';

const { Header, Content } = Layout;

interface FilesContainerProps {
  singleClick: boolean;
}

const FilesContainer: React.FC<FilesContainerProps> = ({ singleClick }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [files, setFiles] = useState<IFile[]>([]);
  const [filteredFiles, setFilteredFiles] = useState<IFile[]>(files);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [actionPerformed, setActionPerformed] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
  const { path } = useParams();
  const user = 'shared';
  const location = useLocation();
  const navigate = useNavigate();
  const Search = Input.Search;

  const navigationHistory = path ? path.split('.') : [''];

  useEffect(() => {
    setSelectedFiles([]);
    getFolderContent((user ? user : '') + (path ? '/' + path.replace('.', '/') : ''))
      .then((res) => {
        if (res.files) {
          setFiles(res.files);
          setFilteredFiles(res.files);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [actionPerformed]);

  const deleteSlash = (path: string) => {
    var splitedPath = path.split('/');
    if (splitedPath[splitedPath.length - 1] === '') {
      splitedPath.pop();
      path = splitedPath.join('/');
    }

    return path;
  };

  const fullPath = (user ? user : '') + (path ? '/' + deleteSlash(path.replace('.', '/')) : '');

  const obtainSubpath = (index: number) => {
    return navigationHistory.slice(0, index + 1).join('.');
  };

  const breadcrumbItems = navigationHistory.slice(0, -1).map((subpath: string, index: number) => {
    return (
      <Breadcrumb.Item key={'breadcrumb' + index} className={styles.breadcrumbItem}>
        <Link to={`/files/${user}/${obtainSubpath(index)}`} onClick={() => setActionPerformed(!actionPerformed)}>
          {subpath}
        </Link>
      </Breadcrumb.Item>
    );
  });

  const handleGetFile = (path: string) => {
    var newWindow = window.open();
    newWindow?.blur();
    getFile(path)
      .then((res) => {
        if (res.url) {
          if (newWindow === null) {
            return window.open(res.url);
          }
          newWindow.location = res.url;
          newWindow.focus();
          return;
        }
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.description);
          return;
        }
        message.error('Error al Comunicarse con el Servidor');
      });
  };

  const filterFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currValue = e.target.value;
    const filteredData = files.filter((entry) => entry.filename.toLowerCase().includes(currValue.toLowerCase()));
    setFilteredFiles(filteredData);
  };

  const handleOpenFolder = (folderPath: string) => {
    folderPath = deleteSlash(folderPath);
    navigate(`/files/${user}/${folderPath.split('/').slice(1, undefined).join('.')}`);
    setActionPerformed(!actionPerformed);
  };

  const navigateBackwards = () => {
    navigate(`/files/${user}/${navigationHistory.slice(0, -1).join('.')}`);
    setActionPerformed(!actionPerformed);
  };
  return (
    <div>
      <Header className={styles.navbar}>
        <MediaQuery query={'(min-device-width: 1024px)'}>
          <Breadcrumb className={styles.breadcrumb} style={{ margin: '16px 16px' }}>
            <Breadcrumb.Item key={'breadcrumb inicio'} className={styles.breadcrumbItem}>
              <Link to={`/files/${user}/`} replace={false} onClick={() => setActionPerformed(!actionPerformed)}>
                Inicio
              </Link>
            </Breadcrumb.Item>
            {breadcrumbItems}
          </Breadcrumb>
        </MediaQuery>
        <MediaQuery query={'(max-device-width: 1024px)'}>
          <Button onClick={navigateBackwards} className={styles.backButton}>
            <IoChevronBackCircleOutline className={styles.icon} />
          </Button>
        </MediaQuery>
        <MediaQuery query={'(max-device-width: 500px)'}>
          <Input
            className={styles.search}
            size="middle"
            suffix={<IoSearchOutline />}
            placeholder="Buscar"
            onChange={filterFiles}
          />
        </MediaQuery>
        <MediaQuery query={'(min-device-width: 501px)'}>
          <Search className={styles.search} size="middle" placeholder="Buscar" onChange={filterFiles} enterButton />
        </MediaQuery>
        {isAdmin() ? (
          <Menu mode="horizontal" className={styles.menu} selectable={true}>
            <Menu.Item className={styles.menuButtons} key={'eliminar'} onClick={() => setShowDeleteModal(true)}>
              <Button className={styles.delete} type="primary">
                <AiOutlineDelete className={styles.icon} />
              </Button>
            </Menu.Item>
            <Menu.Item className={styles.menuButtons} key={'cargar'}>
              <Button className={styles.upload} type="primary" onClick={() => setShowUploadModal(true)}>
                <AiOutlineUpload className={styles.icon} />
              </Button>
            </Menu.Item>
            <Menu.Item className={styles.menuButtons} key={'crear'}>
              <Button className={styles.createFolder} type="primary" onClick={() => setShowCreateFolderModal(true)}>
                <AiOutlineFolderAdd className={styles.icon} />
              </Button>
            </Menu.Item>
          </Menu>
        ) : (
          <></>
        )}
      </Header>
      <Divider className={styles.divider} />
      <Content className={styles.content}>
        <Row key={5} style={{ margin: '20px' }}>
          {filteredFiles.map((file, index) => {
            return (
              <Col key={`col ${index}`} xs={24} sm={12} md={8} lg={6} xl={4}>
                <FileCard
                  singleClick={singleClick}
                  key={file.path}
                  handleGetFile={handleGetFile}
                  selected={selectedFiles}
                  setSelected={setSelectedFiles}
                  file={file}
                  handleOpenFolder={handleOpenFolder}
                />
              </Col>
            );
          })}
        </Row>
      </Content>

      <ActionModals
        user={user ? user : ''}
        path={fullPath}
        actionPerformed={actionPerformed}
        setActionPerformed={setActionPerformed}
        selectedFiles={selectedFiles}
        showCreate={showCreateFolderModal}
        setShowCreate={setShowCreateFolderModal}
        showUpload={showUploadModal}
        setShowUpload={setShowUploadModal}
        showDelete={showDeleteModal}
        setShowDelete={setShowDeleteModal}
        setSelectedFiles={setSelectedFiles}
      />
    </div>
  );
};

export default FilesContainer;
