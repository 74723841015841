import { FunctionComponent, useLayoutEffect, useState } from 'react';
import AvatarIcon from './components/avatar-icon/AvatarIcon';
import AvatarProps from './resources/AvatarProps.interface';
import { AvatarVariant, AvatarSizes } from './resources/Avatar.enum';
import AvatarDescription from './components/avatar-description/AvatarDescription';
import LoaderSrc from './assets/avatar-loading-xxl.svg';
import ImageDefault from './assets/avatar-md@2x.png';

const styles = require('./Avatar.module.scss');
const Avatar: FunctionComponent<AvatarProps> = ({
  variant = AvatarVariant.Circle,
  sizes = AvatarSizes.Medium,
  src = ImageDefault,
  imgAlt = 'Image name',
  color = '#ffffff',
  backgroundColor = '#fffff',
  borderColor,
  isLoading = false,
  icon,
  className,
  description
}) => {
  const image = <img src={src} alt={imgAlt} />;
  const Styles = {
    color: color,
    backgroundColor: backgroundColor,
    border: borderColor ? `2px solid ${borderColor}` : 'none'
  };
  const loaderImage = <img className={styles.loader} src={LoaderSrc} alt="Avatar loader" />;
  const [classNames, setClassNames] = useState('');
  useLayoutEffect(() => {
    setClassNames(
      [styles.container, styles[`${variant}`], styles[`${sizes}`], className].filter((item) => item).join(' ')
    );
  }, [className, variant, sizes]);

  return (
    <div className={styles.avatarContainer}>
      <div className={classNames} style={Styles}>
        {isLoading && loaderImage}
        {icon ? <AvatarIcon icon={icon} /> : image}
      </div>
      {description && (
        <AvatarDescription
          name={description.primaryText}
          rol={description.secundaryText}
          identifier={description.thirdText}
        ></AvatarDescription>
      )}
    </div>
  );
};

export default Avatar;
